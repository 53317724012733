.project-page-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project-page-fields-wrapper {
  display: flex;
  align-items: center;
}
.project-page-rules-list ul {
  list-style: none;
  padding: 0;
}
.project-page-rules-list {
  padding-left: 30px;
  padding-right: 15px;
  width: 100%;
}
.project-page-btn-list-wrapper {
  display: grid;
  gap: 4px;
  height: 50%;
  min-width: 200px;

  margin: 55px 0 0 0;
}
.project-page-truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Custom styles for ListGroup.Item */
.project-page-custom-list-item {
  background-color: #ffffff;
  /* Change background color */
  color: #495057;
  /* Change text color */
  border: 1px solid #dee2e6;
  /* Change border color */
  border-radius: 0.25rem;
  /* Adjust border-radius if needed */
  padding: 0.5rem;
  /* Adjust padding as needed */
  cursor: pointer !important;
  /* Add a pointer cursor for interaction */

  /* Hover effect */
  transition: background-color 0.3s ease;
}

.project-page-custom-list-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Change background color on hover */
}

@media screen and (max-width: 769px) {
  .project-page-card-wrapper {
    display: block;
  }
  .project-page-btn-list-wrapper {
    margin-left: 0;
  }
  .project-page-rules-list {
    width: 100%;
    padding: 0;
  }
}
