canvas {
  cursor: pointer;
}
.project-boxes-truncate-text {
  display: block;
  width: 125px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 769px) {
  .project-boxes-truncate-text {
    width: 200px;
  }
}
