.files-info {
  display: flex;
  position: relative;
  margin-bottom: 10px;
}

.files-info--upload {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 500px) {
  .files-info {
    flex-direction: column;
    gap: 10px;
  }

  .files-info--upload {
    position: static;
  }
}
