.table-wrapper {
  overflow: scroll;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 15px;
    pointer-events: none;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.2), transparent);
    z-index: 1;
  }
}

.react-table {
  min-width: 100%;
  thead {
    th {
      color: #344050;
      background-color: #d5e5fa;
      font-size: 14px;
    }
  }

  tbody {
    tr:nth-child(2n) {
      background-color: #edf2f9;
    }

    tr:hover {
      background-color: #d8e2ef !important;
    }
  }
}

//-- Pagination ---

.btn-no-outline {
  background: transparent;
  border: none;
}

//-- hilight -------------

.highlight {
  background-color: #ffff99;
  padding: 0 2px;
  border-radius: 2px;
}
