.files-page-img {
  max-width: 250px;
}

.files-page-img-wrapper {
  margin-top: 53px;
}

.files-page-field {
  max-width: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;
  font-size: 16px;
}

.files-page-collapse-textarea {
  margin-bottom: 10px;

  font-size: 14px !important;
}

@media screen and (max-width: 993px) {
  .files-page-field {
    width: 400px;
  }
}
@media screen and (max-width: 769px) {
  .files-page-img-wrapper {
    margin-top: 0;
  }
  .files-page-field {
    width: 200px;
  }
}
.page-info-table {
  tbody {
    td {
      font-size: 14px;
      padding-top: 0;
    }
  }
}
.lock-disabled {
  pointer-events: none;
  opacity: 0.7;
}

/* fixed header */

.fixed-header {
  position: fixed;
  width: 817px;
  z-index: 1050;
  padding: 10px 0;
  transition: background-color 0.3s ease;
  background-color: transparent;
}

.empty {
  position: fixed;
  width: 858px;
  height: 50px;
  z-index: 1040;
}

.empty.scrolled {
  background-color: #edf2f9;
}

@media screen and (min-width: 1540px) {
  .fixed-header {
    width: 1157px;
  }
  .empty {
    width: 1198px;
  }
}

@media screen and (max-width: 1199px) {
  .fixed-header {
    width: 887px;
  }
  .empty {
    width: 928px;
  }
}

@media screen and (max-width: 993px) {
  .fixed-header {
    width: 650px;
  }
  .empty {
    width: 688px;
  }
}

@media screen and (max-width: 769px) {
  .fixed-header {
    width: 468px;
  }
  .empty {
    width: 508px;
  }
}
@media screen and (max-width: 539px) {
  .fixed-header {
    width: 380px;
    scale: 0.8;
  }
  .empty {
    width: 508px;
  }
}
