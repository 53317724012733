/* fixing react-bootstrap modal glitch */
.modal.fade.show {
  padding-right: 0 !important;
}

/*fix font*/
.poppins-regular {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol !important;
}

/*fix displaying message*/
.invalid-feedback {
  display: block !important;
  text-align: left;
}

/*fix background of droppable in rules */
.rule-droppable-column {
  max-height: 600px;
  overflow: scroll;
}

.rule-droppable-column > div {
  background-color: #f5f5f5 !important;
}

.form-label {
  font-size: 1rem !important;
  color: #5e6e82 !important;
}

/*svg spinner rotation*/
.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
