.rules-table {
  thead {
    th {
      background-color: #e9ecef;
      text-align: left !important;
      &:first-child {
        padding-left: 20px !important;
      }
    }
  }
  tbody {
    td {
      padding: 13px !important;
      font-weight: bold;
    }
  }
}
