.react-table-pagination {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
  gap: 10px;
  flex-grow: 1;

  .active {
    background-color: #0f172ae6;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
  }

  .page-item {
    list-style: none;
  }

  .page-item a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
  }

  .disabled {
    a {
      opacity: 0.8;
      color: #ccc;
      pointer-events: none;
    }
  }
}
